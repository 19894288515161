@keyframes stronger-shaking {
  0% {
    transform: translate(9px, 4px) rotate(-1.8deg);
  }

  10% {
    transform: translate(3px, -4px) rotate(1.6deg);
  }

  20% {
    transform: translate(-8px, 7px) rotate(-0.7deg);
  }

  30% {
    transform: translate(9px, -5px) rotate(-1.35deg);
  }

  40% {
    transform: translate(-5px, 6px) rotate(-1.25deg);
  }

  50% {
    transform: translate(-9px, 10px) rotate(-2.3deg);
  }

  60% {
    transform: translate(-8px, 9px) rotate(0.6deg);
  }

  70% {
    transform: translate(7px, -6px) rotate(-1.3deg);
  }

  80% {
    transform: translate(-1px, -4px) rotate(0.5deg);
  }

  90% {
    transform: translate(10px, -9px) rotate(-0.95deg);
  }

  100% {
    transform: translate(9px, 4px) rotate(-0.95deg);
  }
}

@keyframes shaking {
  0% {
    transform: translate(0px, 0px) rotate(2.1deg);
  }

  10% {
    transform: translate(1px, 1px) rotate(-2.4deg);
  }

  20% {
    transform: translate(1px, 1px) rotate(-1.1deg);
  }

  30% {
    transform: translate(1px, 1px) rotate(-0.1deg);
  }

  40% {
    transform: translate(1px, 0px) rotate(-2.35deg);
  }

  50% {
    transform: translate(1px, 0px) rotate(0.65deg);
  }

  60% {
    transform: translate(0px, 1px) rotate(0.75deg);
  }

  70% {
    transform: translate(1px, 1px) rotate(1.4deg);
  }

  80% {
    transform: translate(1px, 1px) rotate(0.5deg);
  }

  90% {
    transform: translate(1px, 1px) rotate(-1.2deg);
  }

  100% {
    transform: translate(0px, 1px) rotate(-0.1deg);
  }
}

@keyframes rocking {
  0% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(5deg);
  }
}

@keyframes orbiting {
  0% {
    transform: rotate(0deg) translateX(50%);
  }
  100% {
    transform: rotate(359deg) translateX(50%);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes sliding {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes skewing {
  from,
  11.1%,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

@keyframes shake-lr {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  10% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  80% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }
  90% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }
}

@keyframes jello-vertical {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
    transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes shake {
  2% {
    transform: translate(1px, 5px) rotate(-1.5deg);
  }
  4% {
    transform: translate(7px, 4px) rotate(-2.5deg);
  }
  6% {
    transform: translate(8px, -2px) rotate(-0.5deg);
  }
  8% {
    transform: translate(2px, 10px) rotate(0.5deg);
  }
  10% {
    transform: translate(3px, 0px) rotate(-1.5deg);
  }
  12% {
    transform: translate(-8px, 9px) rotate(-1.5deg);
  }
  14% {
    transform: translate(-9px, -6px) rotate(-0.5deg);
  }
  16% {
    transform: translate(8px, -2px) rotate(0.5deg);
  }
  18% {
    transform: translate(-1px, -5px) rotate(1.5deg);
  }
  20% {
    transform: translate(-2px, -5px) rotate(-2.5deg);
  }
  22% {
    transform: translate(-4px, -3px) rotate(-2.5deg);
  }
  24% {
    transform: translate(-7px, 5px) rotate(-0.5deg);
  }
  26% {
    transform: translate(-5px, 5px) rotate(1.5deg);
  }
  28% {
    transform: translate(-1px, 6px) rotate(3.5deg);
  }
  30% {
    transform: translate(-2px, 6px) rotate(-0.5deg);
  }
  32% {
    transform: translate(2px, 10px) rotate(-0.5deg);
  }
  34% {
    transform: translate(-9px, 1px) rotate(0.5deg);
  }
  36% {
    transform: translate(-7px, 5px) rotate(3.5deg);
  }
  38% {
    transform: translate(-7px, 9px) rotate(1.5deg);
  }
  40% {
    transform: translate(7px, -8px) rotate(-0.5deg);
  }
  42% {
    transform: translate(8px, 7px) rotate(-0.5deg);
  }
  44% {
    transform: translate(-3px, -2px) rotate(0.5deg);
  }
  46% {
    transform: translate(1px, 2px) rotate(-2.5deg);
  }
  48% {
    transform: translate(-6px, -2px) rotate(-2.5deg);
  }
  50% {
    transform: translate(-8px, -1px) rotate(-1.5deg);
  }
  52% {
    transform: translate(5px, 10px) rotate(-1.5deg);
  }
  54% {
    transform: translate(7px, -9px) rotate(3.5deg);
  }
  56% {
    transform: translate(-7px, -5px) rotate(0.5deg);
  }
  58% {
    transform: translate(3px, 3px) rotate(0.5deg);
  }
  60% {
    transform: translate(4px, -5px) rotate(3.5deg);
  }
  62% {
    transform: translate(9px, 9px) rotate(1.5deg);
  }
  64% {
    transform: translate(-5px, 10px) rotate(-0.5deg);
  }
  66% {
    transform: translate(-8px, -9px) rotate(-2.5deg);
  }
  68% {
    transform: translate(-4px, -8px) rotate(-2.5deg);
  }
  70% {
    transform: translate(6px, -8px) rotate(1.5deg);
  }
  72% {
    transform: translate(8px, -6px) rotate(-1.5deg);
  }
  74% {
    transform: translate(-6px, 7px) rotate(1.5deg);
  }
  76% {
    transform: translate(2px, 5px) rotate(-1.5deg);
  }
  78% {
    transform: translate(2px, 0px) rotate(-2.5deg);
  }
  80% {
    transform: translate(-7px, 3px) rotate(-2.5deg);
  }
  82% {
    transform: translate(-9px, -4px) rotate(0.5deg);
  }
  84% {
    transform: translate(4px, 9px) rotate(3.5deg);
  }
  86% {
    transform: translate(-7px, 6px) rotate(-0.5deg);
  }
  88% {
    transform: translate(3px, -3px) rotate(-1.5deg);
  }
  90% {
    transform: translate(1px, -3px) rotate(-0.5deg);
  }
  92% {
    transform: translate(4px, 3px) rotate(2.5deg);
  }
  94% {
    transform: translate(9px, 1px) rotate(3.5deg);
  }
  96% {
    transform: translate(-8px, 3px) rotate(0.5deg);
  }
  98% {
    transform: translate(-8px, -7px) rotate(-2.5deg);
  }
  0%,
  100% {
    transform: translate(0, 0) rotate(0);
  }
}

[rotating] {
  animation: rotating 5s infinite linear;
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

@keyframes pinchAndZoom {
  $translate: 10%;
  $minScale: .8;
  $maxScale: 1;

  0%, 10%, 20%, 30%, 40%, 50%, 60% {
    opacity: 1;
    transform-origin: bottom;
    transform: translateX($translate * -1) scale($maxScale);
  }

  2%, 12%, 22%, 32%, 42%, 52%, 62% {
    opacity: 1;
    transform-origin: bottom;
    transform: translateX($translate * -1) scale($minScale) rotateZ(-15deg);
  }

  5%, 15%, 25%, 35%, 45%, 55%, 65% {
    opacity: 1;
    transform-origin: bottom;
    transform: translateX($translate) scale($minScale) rotateZ(15deg);
  }

  8%, 18%, 28%, 38%, 48%, 58%, 68% {
    opacity: 1;
    transform-origin: bottom;
    transform: translateX($translate) scale($maxScale);
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes lining-down {
  0% {
    opacity: 0;
    transform-origin: top;
    transform: scaleY(0);
  }

  48% {
    opacity: 1;
    transform-origin: top;
    transform: scaleY(1);
  }

  70% {
    transform-origin: bottom;
    transform: scaleY(1);
  }

  100% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
}
