body {
  font-family: "Red Hat Text", sans-serif;
  color: $secondary;
}

html {
  font-size: $font-size-min;
}

h1, h2, h3, h4, h5, h6, h7 {
  font-family: "Red Hat Display", "Red Hat Text", sans-serif;
}

a:active,
a:focus {
  outline: none;
}