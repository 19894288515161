@import './vars';

.floater {
  @include media(lt-md) {
    display: none;
  }

  $size: 3rem;
  $size-large: $size * 1.5;
  $lg-multiplier: 3;
  $animation-duration: 50s;

  position: relative;
  overflow: visible;
  filter: url(#fancy-goo);
  &.lg {
    filter: url(#fancy-goo-lg);
  }

  @mixin element-size($width, $height: $width) {
    width: $width;
    height: $height;
    left: $width * -0.5;
    top: $height * -0.5;
  }

  .element {
    position: absolute;
    @include element-size($size);
  }

  &.lg .element {
    @include element-size($size * $lg-multiplier);
  }

  .color-primary { background: $primary; }
  .color-secondary { background: $secondary; }
  .color-lime { background: $lime; }
  .color-amber { background: $amber; }
  .color-red { background: $red; }

  .shape-square {
    // Nothing to add :)
  }

  .shape-large-square {
    @include element-size($size-large);

    .floater.lg & {
      @include element-size($size-large * $lg-multiplier);
    }
  }

  .shape-pill {
    @include element-size($size-large, $size);
    border-radius: 200vmax;
  }

  &.lg .shape-pill {
    @include element-size($size-large * $lg-multiplier, $size * $lg-multiplier);
  }

  .shape-circle {
    border-radius: $size * 10;
  }

  .animation-orbiting {
    animation: orbiting $animation-duration infinite linear;
  }

  .animation-sliding {
    animation: sliding $animation-duration alternate infinite ease-in-out;
  }

  .animation-shake-lr {
    animation: shake-lr $animation-duration infinite;
  }

  .animation-skewing {
    animation: skewing $animation-duration infinite;
  }

  &.animation-rotating {
    animation: rotating $animation-duration infinite linear;
  }

  &.animation-skewing {
    animation: skewing $animation-duration infinite;
  }

  &.animation-shake-lr {
    animation: shake-lr $animation-duration infinite;
  }

  &.animation-jello-vertical {
    animation: jello-vertical ($animation-duration * 2) infinite;
  }

  &.animation-none {
    animation: none;
  }
}