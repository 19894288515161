[bg-white] {
  background: #fff;
}

[bg-darken-sm] {
  background: #f5f5f5;
}

[bg-black] {
  background: #000;
}

[bg-primary] {
  background: #29d5d2;
}

[bg-secondary] {
  background: #332148;
}

[bg-tertiary] {
  background: #ff1f8f;
}

[bg-tertiary-light] {
  background: #ff9fcf;
}

[bg-secondary-tint] {
  background: #4c316b;
}

[bg-gray] {
  background: #0000001a;
}

[bg-red] {
  background: red;
}

[bg-gradient] {
  background: linear-gradient(to top, #29d5d2 10%, #332148 100%);
}

[bg-secondary-to-white] {
  background: linear-gradient(to top, #fff 0%, #f5f5f5 10%, #332148 120%);
}

[bg-gradient-primary-tip] {
  background: linear-gradient(to top left, #29d5d2 0%, #332148 40%);
}

[bg-gradient-amber-tip] {
  background: linear-gradient(to top left, #ffc000 -20%, #fff 20%);
}

[bg-gradient-lime-tip] {
  background: linear-gradient(to top left, #bfff00 -100%, #fff 20%);
}

[padding], section[card] {
  padding: 1rem;
}

[padding-sm] {
  padding: .5rem;
}

[padding-lg] {
  padding: 2rem;
}

[margin], section[card] {
  margin: 1rem;
}

[margin-bt] {
  margin-bottom: 1rem;
}

[margin-bt-lg] {
  margin-bottom: 2rem;
}

[margin-rt] {
  margin-right: 1rem;
}

[margin-sm] {
  margin: .5rem;
}

[margin-lg] {
  margin: 2rem;
}

[max-width-20] {
  max-width: 20rem;
}

[fill], card-bg {
  position: absolute;
  inset: 0;
}

[color-inverse], [color-inverse] a {
  color: #f5f5f5;
}

[shadow-diffuse] {
  box-shadow: 0 1rem 2rem #332148;
}

[flex-row] {
  flex-direction: row;
  display: flex;
}

@media screen and (width <= 1000px) {
  [flex-row][responsive] {
    flex-direction: column;
  }
}

[flex-row][align-start], [flex-column][align-start] {
  align-items: flex-start;
}

[flex-row][align-baseline], [flex-column][align-baseline] {
  align-items: baseline;
}

[flex-row][align-center], [flex-column][align-center] {
  align-items: center;
}

[flex-row][center], [flex-column][center] {
  justify-content: center;
  align-items: center;
}

[flex-row][space-between], [flex-column][space-between] {
  justify-content: space-between;
}

[flex-row][space-around], [flex-column][space-around] {
  justify-content: space-around;
}

[flex-row][justify-center], [flex-column][justify-center] {
  justify-content: center;
}

[flex-row][justify-stretch], [flex-column][justify-stretch] {
  justify-content: stretch;
}

[flex-column] {
  flex-direction: column;
  display: flex;
}

[flex-wrap] {
  flex-wrap: wrap;
}

[flex-fill] {
  flex: 1;
}

[flex-center] {
  justify-content: center;
  align-items: center;
}

[text-center] {
  text-align: center;
}

[text-right] {
  text-align: right;
}

[text-fuzzy] {
  text-shadow: -4px 0 #29d5d21a, 4px 0 #29d5d21a, -2px 0 #29d5d266, 2px 0 #29d5d266;
}

[text-red] {
  color: red;
}

[text-underline] {
  text-decoration: underline;
}

[one-liner] {
  height: 1em;
}

.offscreen {
  position: fixed;
  left: -9999px;
}

section[fullscreen] {
  width: 100%;
  min-height: 70vh;
}

section[card] {
  border-radius: 1rem;
  max-width: 20rem;
  position: relative;
  box-shadow: 0 1rem 2rem -.5rem #0003, 0 0 .2rem .1rem #0000000d;
}

section[card] card-bg {
  z-index: -1;
}

@media screen and (width <= 1000px) {
  section[card] {
    max-width: 90vw;
  }
}

section {
  position: relative;
}

p.description {
  text-align: center;
  color: #0009;
  font-size: .7em;
}

.section-header {
  text-align: center;
  font-size: 2rem;
}

@media screen and (width <= 400px) {
  .section-header {
    font-size: 1.3rem;
  }
}

@media screen and (width >= 400px) and (width <= 1000px) {
  .section-header {
    font-size: 1.5rem;
  }
}

@keyframes stronger-shaking {
  0% {
    transform: translate(9px, 4px)rotate(-1.8deg);
  }

  10% {
    transform: translate(3px, -4px)rotate(1.6deg);
  }

  20% {
    transform: translate(-8px, 7px)rotate(-.7deg);
  }

  30% {
    transform: translate(9px, -5px)rotate(-1.35deg);
  }

  40% {
    transform: translate(-5px, 6px)rotate(-1.25deg);
  }

  50% {
    transform: translate(-9px, 10px)rotate(-2.3deg);
  }

  60% {
    transform: translate(-8px, 9px)rotate(.6deg);
  }

  70% {
    transform: translate(7px, -6px)rotate(-1.3deg);
  }

  80% {
    transform: translate(-1px, -4px)rotate(.5deg);
  }

  90% {
    transform: translate(10px, -9px)rotate(-.95deg);
  }

  100% {
    transform: translate(9px, 4px)rotate(-.95deg);
  }
}

@keyframes shaking {
  0% {
    transform: translate(0)rotate(2.1deg);
  }

  10% {
    transform: translate(1px, 1px)rotate(-2.4deg);
  }

  20% {
    transform: translate(1px, 1px)rotate(-1.1deg);
  }

  30% {
    transform: translate(1px, 1px)rotate(-.1deg);
  }

  40% {
    transform: translate(1px)rotate(-2.35deg);
  }

  50% {
    transform: translate(1px)rotate(.65deg);
  }

  60% {
    transform: translate(0, 1px)rotate(.75deg);
  }

  70% {
    transform: translate(1px, 1px)rotate(1.4deg);
  }

  80% {
    transform: translate(1px, 1px)rotate(.5deg);
  }

  90% {
    transform: translate(1px, 1px)rotate(-1.2deg);
  }

  100% {
    transform: translate(0, 1px)rotate(-.1deg);
  }
}

@keyframes rocking {
  0% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(5deg);
  }
}

@keyframes orbiting {
  0% {
    transform: rotate(0)translateX(50%);
  }

  100% {
    transform: rotate(359deg)translateX(50%);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes sliding {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes skewing {
  from, 11.1%, to {
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

@keyframes shake-lr {
  0%, 100% {
    transform-origin: 50%;
    transform: rotate(0);
  }

  10% {
    transform: rotate(8deg);
  }

  20%, 40%, 60% {
    transform: rotate(-10deg);
  }

  30%, 50%, 70% {
    transform: rotate(10deg);
  }

  80% {
    transform: rotate(-8deg);
  }

  90% {
    transform: rotate(8deg);
  }
}

@keyframes jello-vertical {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(.75, 1.25, 1);
  }

  40% {
    transform: scale3d(1.25, .75, 1);
  }

  50% {
    transform: scale3d(.85, 1.15, 1);
  }

  65% {
    transform: scale3d(1.05, .95, 1);
  }

  75% {
    transform: scale3d(.95, 1.05, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes shake {
  2% {
    transform: translate(1px, 5px)rotate(-1.5deg);
  }

  4% {
    transform: translate(7px, 4px)rotate(-2.5deg);
  }

  6% {
    transform: translate(8px, -2px)rotate(-.5deg);
  }

  8% {
    transform: translate(2px, 10px)rotate(.5deg);
  }

  10% {
    transform: translate(3px)rotate(-1.5deg);
  }

  12% {
    transform: translate(-8px, 9px)rotate(-1.5deg);
  }

  14% {
    transform: translate(-9px, -6px)rotate(-.5deg);
  }

  16% {
    transform: translate(8px, -2px)rotate(.5deg);
  }

  18% {
    transform: translate(-1px, -5px)rotate(1.5deg);
  }

  20% {
    transform: translate(-2px, -5px)rotate(-2.5deg);
  }

  22% {
    transform: translate(-4px, -3px)rotate(-2.5deg);
  }

  24% {
    transform: translate(-7px, 5px)rotate(-.5deg);
  }

  26% {
    transform: translate(-5px, 5px)rotate(1.5deg);
  }

  28% {
    transform: translate(-1px, 6px)rotate(3.5deg);
  }

  30% {
    transform: translate(-2px, 6px)rotate(-.5deg);
  }

  32% {
    transform: translate(2px, 10px)rotate(-.5deg);
  }

  34% {
    transform: translate(-9px, 1px)rotate(.5deg);
  }

  36% {
    transform: translate(-7px, 5px)rotate(3.5deg);
  }

  38% {
    transform: translate(-7px, 9px)rotate(1.5deg);
  }

  40% {
    transform: translate(7px, -8px)rotate(-.5deg);
  }

  42% {
    transform: translate(8px, 7px)rotate(-.5deg);
  }

  44% {
    transform: translate(-3px, -2px)rotate(.5deg);
  }

  46% {
    transform: translate(1px, 2px)rotate(-2.5deg);
  }

  48% {
    transform: translate(-6px, -2px)rotate(-2.5deg);
  }

  50% {
    transform: translate(-8px, -1px)rotate(-1.5deg);
  }

  52% {
    transform: translate(5px, 10px)rotate(-1.5deg);
  }

  54% {
    transform: translate(7px, -9px)rotate(3.5deg);
  }

  56% {
    transform: translate(-7px, -5px)rotate(.5deg);
  }

  58% {
    transform: translate(3px, 3px)rotate(.5deg);
  }

  60% {
    transform: translate(4px, -5px)rotate(3.5deg);
  }

  62% {
    transform: translate(9px, 9px)rotate(1.5deg);
  }

  64% {
    transform: translate(-5px, 10px)rotate(-.5deg);
  }

  66% {
    transform: translate(-8px, -9px)rotate(-2.5deg);
  }

  68% {
    transform: translate(-4px, -8px)rotate(-2.5deg);
  }

  70% {
    transform: translate(6px, -8px)rotate(1.5deg);
  }

  72% {
    transform: translate(8px, -6px)rotate(-1.5deg);
  }

  74% {
    transform: translate(-6px, 7px)rotate(1.5deg);
  }

  76% {
    transform: translate(2px, 5px)rotate(-1.5deg);
  }

  78% {
    transform: translate(2px)rotate(-2.5deg);
  }

  80% {
    transform: translate(-7px, 3px)rotate(-2.5deg);
  }

  82% {
    transform: translate(-9px, -4px)rotate(.5deg);
  }

  84% {
    transform: translate(4px, 9px)rotate(3.5deg);
  }

  86% {
    transform: translate(-7px, 6px)rotate(-.5deg);
  }

  88% {
    transform: translate(3px, -3px)rotate(-1.5deg);
  }

  90% {
    transform: translate(1px, -3px)rotate(-.5deg);
  }

  92% {
    transform: translate(4px, 3px)rotate(2.5deg);
  }

  94% {
    transform: translate(9px, 1px)rotate(3.5deg);
  }

  96% {
    transform: translate(-8px, 3px)rotate(.5deg);
  }

  98% {
    transform: translate(-8px, -7px)rotate(-2.5deg);
  }

  0%, 100% {
    transform: translate(0)rotate(0);
  }
}

[rotating] {
  animation: 5s linear infinite rotating;
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

@keyframes pinchAndZoom {
  0%, 10%, 20%, 30%, 40%, 50%, 60% {
    opacity: 1;
    transform-origin: bottom;
    transform: translateX(-10%)scale(1);
  }

  2%, 12%, 22%, 32%, 42%, 52%, 62% {
    opacity: 1;
    transform-origin: bottom;
    transform: translateX(-10%)scale(.8)rotateZ(-15deg);
  }

  5%, 15%, 25%, 35%, 45%, 55%, 65% {
    opacity: 1;
    transform-origin: bottom;
    transform: translateX(10%)scale(.8)rotateZ(15deg);
  }

  8%, 18%, 28%, 38%, 48%, 58%, 68% {
    opacity: 1;
    transform-origin: bottom;
    transform: translateX(10%)scale(1);
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes lining-down {
  0% {
    opacity: 0;
    transform-origin: top;
    transform: scaleY(0);
  }

  48% {
    opacity: 1;
    transform-origin: top;
    transform: scaleY(1);
  }

  70% {
    transform-origin: bottom;
    transform: scaleY(1);
  }

  100% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
}

.floater {
  filter: url("#fancy-goo");
  position: relative;
  overflow: visible;
}

@media screen and (width <= 1000px) {
  .floater {
    display: none;
  }
}

.floater.lg {
  filter: url("#fancy-goo-lg");
}

.floater .element {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: -1.5rem;
  left: -1.5rem;
}

.floater.lg .element {
  width: 9rem;
  height: 9rem;
  top: -4.5rem;
  left: -4.5rem;
}

.floater .color-primary {
  background: #29d5d2;
}

.floater .color-secondary {
  background: #332148;
}

.floater .color-lime {
  background: #bfff00;
}

.floater .color-amber {
  background: #ffc000;
}

.floater .color-red {
  background: red;
}

.floater .shape-large-square {
  width: 4.5rem;
  height: 4.5rem;
  top: -2.25rem;
  left: -2.25rem;
}

.floater.lg .floater .shape-large-square {
  width: 13.5rem;
  height: 13.5rem;
  top: -6.75rem;
  left: -6.75rem;
}

.floater .shape-pill {
  border-radius: 200vmax;
  width: 4.5rem;
  height: 3rem;
  top: -1.5rem;
  left: -2.25rem;
}

.floater.lg .shape-pill {
  width: 13.5rem;
  height: 9rem;
  top: -4.5rem;
  left: -6.75rem;
}

.floater .shape-circle {
  border-radius: 30rem;
}

.floater .animation-orbiting {
  animation: 50s linear infinite orbiting;
}

.floater .animation-sliding {
  animation: 50s ease-in-out infinite alternate sliding;
}

.floater .animation-shake-lr {
  animation: 50s infinite shake-lr;
}

.floater .animation-skewing {
  animation: 50s infinite skewing;
}

.floater.animation-rotating {
  animation: 50s linear infinite rotating;
}

.floater.animation-skewing {
  animation: 50s infinite skewing;
}

.floater.animation-shake-lr {
  animation: 50s infinite shake-lr;
}

.floater.animation-jello-vertical {
  animation: 100s infinite jello-vertical;
}

.floater.animation-none {
  animation: none;
}

.splitter {
  z-index: 1;
  filter: url("#fancy-goo-lg");
}

@media screen and (width <= 400px) {
  .splitter {
    filter: url("#fancy-goo");
  }
}

.splitter .circle-container {
  overflow: visible;
}

.splitter .circle {
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.modal {
  display: none;
}

@media screen and (width <= 1000px) {
  .modal {
    font-size: .6rem;
  }
}

.modal .modal-overlay {
  z-index: 1;
  background: #0009;
  place-items: center;
  padding: 2rem;
  display: grid;
  position: fixed;
  inset: 0;
  overflow: auto;
}

@media screen and (width <= 400px) {
  .modal .modal-overlay {
    padding: .5rem;
  }
}

.modal .modal-container {
  background: #fff;
  border-radius: .5rem;
  flex-direction: column;
  max-width: 90vw;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  box-shadow: 0 1rem 2rem #0006;
}

.modal .modal-container .modal-content {
  margin: 0 -1rem;
}

@media screen and (width <= 400px) {
  .modal .modal-container {
    max-width: 95vw;
  }
}

@media screen and (width >= 400px) and (width <= 1000px) {
  .modal .modal-container {
    max-width: 90vw;
  }
}

@media screen and (width >= 1000px) {
  .modal .modal-container {
    max-width: min(80vw, 1024px);
  }
}

.modal .modal-content {
  padding: 0 1rem;
  overflow: auto;
}

.modal .modal-content > :first-child {
  margin-top: 1.5rem;
}

.modal .modal-header {
  margin-bottom: .25rem;
}

.modal .modal-header .modal-title {
  flex-grow: 1;
  margin: 0;
}

.modal .modal-close-control {
  border-radius: 100rem;
  flex-shrink: 0;
  align-self: flex-start;
}

.modal .modal-close-control i {
  pointer-events: none;
}

.modal.is-open {
  display: block;
}

.modal-slide {
  display: none;
}

.modal-slide.is-open {
  display: block;
}

.modal-slide[aria-hidden="false"] .modal-overlay {
  animation: .3s cubic-bezier(0, 0, .2, 1) mmfadeIn;
}

.modal-slide[aria-hidden="false"] .modal-container {
  animation: .3s cubic-bezier(0, 0, .2, 1) mmslideIn;
}

.modal-slide[aria-hidden="true"] .modal-overlay {
  animation: .3s cubic-bezier(0, 0, .2, 1) mmfadeOut;
}

.modal-slide[aria-hidden="true"] .modal-container {
  animation: .3s cubic-bezier(0, 0, .2, 1) mmslideOut;
}

.modal-slide .modal-container, .modal-slide .modal-overlay {
  will-change: transform;
}

.modal-content ul.img-section, .timeline-event ul.img-section {
  flex-direction: row;
  margin: .5rem 0;
  padding: 0 .5rem;
  list-style-type: none;
  display: flex;
}

@media screen and (width <= 1000px) {
  .modal-content ul.img-section, .timeline-event ul.img-section {
    padding: 0;
  }
}

.modal-content ul.img-section li, .timeline-event ul.img-section li {
  flex-grow: 1;
  margin: 0 .5rem;
}

@media screen and (width <= 1000px) {
  .modal-content ul.img-section li, .timeline-event ul.img-section li {
    margin: 0 .15rem;
  }
}

.modal-content ul.img-section img, .timeline-event ul.img-section img {
  vertical-align: bottom;
  min-height: 100%;
  margin: 0;
}

.modal-content img, .modal-content video, .timeline-event img, .timeline-event video {
  border-radius: .2rem;
  max-width: 100%;
  max-height: 10rem;
  margin: .5rem auto;
  display: block;
  box-shadow: 0 .1rem .2rem #0003;
}

.modal-content video:active, .modal-content video:focus, .timeline-event video:active, .timeline-event video:focus {
  outline: none;
}

.modal-content p, .timeline-event p {
  margin-bottom: 1rem;
}

.modal-content p + ul, .timeline-event p + ul {
  margin-top: -.25rem;
}

.modal-content ul, .timeline-event ul {
  padding-left: 1rem;
  list-style-type: "🞄 ";
}

.modal-content .twitter-tweet, .timeline-event .twitter-tweet {
  margin: 0 auto;
}

.modal-content iframe, .timeline-event iframe {
  margin: 0 auto;
  display: block;
}

.modal-content code, .timeline-event code {
  letter-spacing: -1px;
  font-size: .8em;
  font-weight: bold;
  line-height: 1em;
}

button {
  color: #fff;
  background: #332148;
  border: none;
  border-radius: .25rem;
  outline: none;
  padding: .2rem;
}

button:hover, button:focus {
  background: #4c316b;
}

button:active {
  background: #332148;
}

.pill {
  text-transform: capitalize;
  cursor: pointer;
  color: #332148;
  background: #fff;
  border-radius: 100rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: .2rem;
  padding: .2rem .5rem;
  font-size: .6rem;
  transition: all .15s ease-in-out;
  display: flex;
  box-shadow: 0 .1rem .2rem #00000026;
}

@media screen and (width <= 1000px) {
  .pill {
    margin: .1rem;
    font-size: .4rem;
  }
}

.pill.selected {
  font-weight: bolder;
  transform: translateY(5%);
  box-shadow: 0 .05rem .125rem #0000001a;
}

.pill.inverse {
  color: #fff9;
  background: #58397c;
  box-shadow: 0 .1rem .2rem #33214859;
}

.pill svg {
  fill: currentColor;
  width: 1em;
  height: 1em;
  margin-right: .25rem;
}

.pill i {
  margin-right: .4rem;
}

body {
  color: #332148;
  font-family: Red Hat Text, sans-serif;
}

html {
  font-size: 32px;
}

h1, h2, h3, h4, h5, h6, h7 {
  font-family: Red Hat Display, Red Hat Text, sans-serif;
}

a:active, a:focus {
  outline: none;
}

html {
  scroll-behavior: smooth;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  --primary: #29d5d2;
  --secondary: #332148;
  --tertiary: #ff1f8f;
  --amber: #ffc000;
  --lime: #bfff00;
}

body.no-scroll {
  overflow: hidden;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  width: .25rem;
  height: .25rem;
}

body::-webkit-scrollbar-track {
  background: #332148;
}

body::-webkit-scrollbar-thumb {
  background: #29d5d2;
}
/*# sourceMappingURL=index.b68c4245.css.map */
