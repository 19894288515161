@import "./vars";

[bg-white] {
  background: white;
}

[bg-darken-sm] {
  background: $darken-sm;
}

[bg-black] {
  background: black;
}

[bg-primary] {
  background: $primary;
}

[bg-secondary] {
  background: $secondary;
}

[bg-tertiary] {
  background: $tertiary;
}

[bg-tertiary-light] {
  background: lighten($tertiary, 25%);
}

[bg-secondary-tint] {
  background: $secondary-tint;
}

[bg-gray] {
  background: rgba(0, 0, 0, 0.1);
}

[bg-red] {
  background: $red;
}

[bg-gradient] {
  background: linear-gradient(to top, $primary 10%, $secondary 100%);
}

[bg-secondary-to-white] {
  background: linear-gradient(
    to top,
    white 0%,
    whitesmoke 10%,
    $secondary 120%
  );
}

[bg-gradient-primary-tip] {
  background: linear-gradient(to top left, $primary 0%, $secondary 40%);
}

[bg-gradient-amber-tip] {
  background: linear-gradient(to top left, $amber -20%, white 20%);
}

[bg-gradient-lime-tip] {
  background: linear-gradient(to top left, $lime -100%, white 20%);
}

[padding] {
  padding: 1rem;
}

[padding-sm] {
  padding: 0.5rem;
}

[padding-lg] {
  padding: 2rem;
}

[margin] {
  margin: 1rem;
}

[margin-bt] {
  margin-bottom: 1rem;
}

[margin-bt-lg] {
  margin-bottom: 2rem;
}

[margin-rt] {
  margin-right: 1rem;
}

[margin-sm] {
  margin: 0.5rem;
}

[margin-lg] {
  margin: 2rem;
}

[max-width-20] {
  max-width: 20rem;
}

[fill] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

[color-inverse] {
  color: $text-inverse;

  a {
    color: $text-inverse;
  }
}

[shadow-diffuse] {
  box-shadow: 0 1rem 2rem 0 $secondary;
}

[flex-row] {
  display: flex;
  flex-direction: row;

  &[responsive] {
    @include media(lt-md) {
      flex-direction: column;
    }
  }
}

[flex-row],
[flex-column] {
  &[align-start] {
    align-items: flex-start;
  }

  &[align-baseline] {
    align-items: baseline;
  }

  &[align-center] {
    align-items: center;
  }

  &[center] {
    align-items: center;
    justify-content: center;
  }

  &[space-between] {
    justify-content: space-between;
  }

  &[space-around] {
    justify-content: space-around;
  }

  &[justify-center] {
    justify-content: center;
  }

  &[justify-stretch] {
    justify-content: stretch;
  }
}

[flex-column] {
  display: flex;
  flex-direction: column;
}

[flex-wrap] {
  flex-wrap: wrap;
}

[flex-fill] {
  flex: 1;
}

[flex-center] {
  justify-content: center;
  align-items: center;
}

[text-center] {
  text-align: center;
}

[text-right] {
  text-align: right;
}

[text-fuzzy] {
  text-shadow: -4px 0 transparentize($primary, 0.9),
    4px 0 transparentize($primary, 0.9), -2px 0 transparentize($primary, 0.6),
    2px 0 transparentize($primary, 0.6);
}

[text-red] {
  color: $red;
}

[text-underline] {
  text-decoration: underline;
}

[one-liner] {
  height: 1em;
}

.offscreen {
  position: fixed;
  left: -9999px;
}

section[fullscreen] {
  min-height: 70vh;
  width: 100%;
}

section[card] {
  position: relative;
  max-width: 20rem;
  @extend [padding];
  @extend [margin];

  border-radius: 1rem;

  box-shadow: 0 1rem 2rem -0.5rem rgba(0, 0, 0, 0.2),
    0 0 0.2rem 0.1rem rgba(0, 0, 0, 0.05);

  card-bg {
    z-index: -1;
  }

  @include media(lt-md) {
    max-width: 90vw;
  }
}

card-bg {
  @extend [fill];
}

section {
  position: relative;
}

p.description {
  text-align: center;
  font-size: 0.7em;
  color: rgba(0, 0, 0, 0.6);
}

.section-header {
  text-align: center;
  font-size: 2rem;

  @include media(sm) {
    font-size: 1.3rem;
  }

  @include media(md) {
    font-size: 1.5rem;
  }
}
