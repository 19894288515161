@import "./vars";

.splitter {
  $size: 20rem;
  $circle-size: $size * 0.5;
  $animation-duration: 30s;

  z-index: 1;
  filter: url(#fancy-goo-lg);

  @include media(sm) {
    filter: url(#fancy-goo);
  }

  .circle-container {
    overflow: visible;
  }

  .circle {
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-duration: $animation-duration;
  }
}
