@import "./vars";

.modal {
  display: none;

  @include media(lt-md) {
    font-size: 0.6rem;
  }

  .modal-overlay {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: grid;
    place-items: center;
    overflow: auto;
    padding: 2rem;

    @include media(sm) {
      padding: 0.5rem;
    }
  }

  .modal-container {
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    background: white;
    max-width: 90vw;
    margin: 0 auto;
    box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.4);
    padding: 1rem;

    .modal-content {
      margin: 0 -1rem;
    }

    @include media(sm) {
      max-width: 95vw;
    }

    @include media(md) {
      max-width: 90vw;
    }

    @include media(lg) {
      max-width: min(80vw, 1024px);
    }
  }

  .modal-content {
    overflow: auto;
    padding: 0 1rem;

    & > *:first-child {
      margin-top: 1.5rem;
    }
  }

  .modal-header {
    margin-bottom: 0.25rem;

    .modal-title {
      flex-grow: 1;
      margin: 0;
    }
  }

  .modal-close-control {
    flex-shrink: 0;
    align-self: flex-start;
    border-radius: 100rem;

    i {
      pointer-events: none;
    }
  }

  &.is-open {
    display: block;
  }
}

.modal-slide {
  display: none;
}

.modal-slide.is-open {
  display: block;
}

.modal-slide[aria-hidden="false"] .modal-overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.modal-slide[aria-hidden="false"] .modal-container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.modal-slide[aria-hidden="true"] .modal-overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.modal-slide[aria-hidden="true"] .modal-container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.modal-slide .modal-container,
.modal-slide .modal-overlay {
  will-change: transform;
}

.modal-content,
.timeline-event {
  ul.img-section {
    margin: 0.5rem 0;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: row;
    list-style-type: none;

    @include media(lt-md) {
      padding: 0;
    }

    li {
      margin: 0 0.5rem;
      flex-grow: 1;

      @include media(lt-md) {
        margin: 0 0.15rem;
      }
    }

    img {
      margin: 0;
      min-height: 100%;
      vertical-align: bottom;
    }
  }

  img,
  video {
    display: block;
    margin: 0.5rem auto;
    max-width: 100%;
    max-height: 10rem;
    border-radius: 0.2rem;
    box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.2);
  }

  video:active,
  video:focus {
    outline: none;
  }

  p {
    margin-bottom: 1rem;
  }

  p + ul {
    margin-top: -0.25rem;
  }

  ul {
    list-style-type: "🞄 ";
    padding-left: 1rem;
  }

  .twitter-tweet {
    margin: 0 auto;
  }

  iframe {
    display: block;
    margin: 0 auto;
  }

  code {
    font-size: 0.8em;
    line-height: 1em;
    font-weight: bold;
    letter-spacing: -1px;
  }
}